<template>
  <div class="relative rounded-2xl bg-dnd_light_grey lg:h-[150px] lg:rounded-4xl">
    <div
      class="gradientButtonXl flex h-[38px] w-[50%] justify-center rounded-br-2xl rounded-tl-2xl bg-dnd_light_purple py-2 text-xs md:ml-4 md:w-[25%] lg:absolute lg:ml-10 lg:h-10 lg:w-52 lg:-translate-y-1/2"
    >
      <div
        class="flex items-center justify-center font-manrope text-xs font-semibold text-black lg:flex lg:text-base lg:text-white"
      >
        Деньги у вас уже в {{ futureTime }}
      </div>
    </div>
    <div
      class="flex h-[403px] flex-col justify-between text-2xl font-semibold md:h-[348px] lg:h-[149px] lg:flex-row lg:items-center lg:text-2xl"
    >
      <div class="ml-6 flex flex-col sm:w-1/2 lg:mx-10">
        <div
          class="block font-extrabold max-lg:pt-6 md:absolute md:flex md:flex-wrap md:items-center md:text-2xl lg:hidden"
        >
          <div>
            <h2>Нужны деньги?&nbsp;</h2>
          </div>
          <div class="md:flex md:items-center">
            Всего лишь
            <span class="rounded-br-2xl rounded-tl-2xl bg-dnd_light_purple px-1.5 py-0.5 md:ml-2">
              15 минут
            </span>
            <div class="hidden md:block lg:hidden">отделяют</div>
          </div>
          <div class="w-full items-start md:space-x-0">
            <div class="hidden md:block lg:hidden">вас от целей</div>
            <div class="block md:hidden lg:block">отделяют вас от целей</div>
          </div>
        </div>
        <div
          class="hidden h-full w-[600px] items-center justify-start font-bold lg:flex lg:text-[28px]"
        >
          <div class="text-left">
            <div>
              Нужны деньги? Всего лишь
              <span class="rounded-br-2xl rounded-tl-2xl bg-dnd_light_purple p-0.5">
                &nbsp;15 минут&nbsp;
              </span>
            </div>
            <div>отделяют вас от целей</div>
          </div>
        </div>
      </div>
      <div class="flex h-full flex-col items-center justify-end lg:hidden">
        <NuxtImg
          src="/images/Girl.png"
          alt="Girl"
          width="344"
          height="270"
          preset="optimizedToWebp"
          loading="lazy"
          class="pointer-events-none absolute bottom-0 max-h-[260px] w-full max-w-[344px] select-none rounded-[24px] max-xxs:right-0 xxs:hidden xs:align-middle md:hidden"
          sizes="344px"
        />

        <NuxtImg
          src="/images/GirlTablet.png"
          alt="Girl"
          loading="lazy"
          preset="optimizedToWebp"
          class="pointer-events-none absolute bottom-0 hidden max-h-[260px] w-full max-w-[344px] select-none max-xxs:right-0 xxs:block xs:align-middle"
          sizes="344px"
        />
      </div>
      <LazyNuxtImg
        src="/images/MotivationMan.png"
        alt="MotivationMan"
        preset="sampleOptimized"
        class="pointer-events-none absolute bottom-0 right-[20%] hidden h-auto w-[400px] transform select-none md:w-[360px] lg:right-[23%] lg:h-[205px] xl:h-auto xl:w-[300px] 2xl:block 3xl:h-[205px] 3xl:w-[360px] 5xl:right-[33%]"
        loading="lazy"
      />
      <NuxtLink
        class="relative mx-4 my-6 lg:mr-8 lg:flex lg:w-1/5 lg:items-center lg:justify-center"
        to="/vzyat-v-dolg"
      >
        <UiButton class="h-[56px] w-full lg:h-[64px]">Оформить займ</UiButton>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs';

const futureTime = ref('');

onMounted(() => {
  futureTime.value = dayjs().add(15, 'minute').format('HH:mm');
});
</script>
